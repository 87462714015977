import React, { useContext } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { useAuthContext } from "../../context/AuthContext";
import ToastHandler from "../custom/ToastHandler";
import FeedbackForm from "../custom/FeedbackForm";
import useBreadcrumbs from "../../utils/useBreadcrumbs";
import PageHelmet from "./PageHelmet";
import { Container } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import LayoutContext from "../../context/LayoutContext";

const Layout = ({
  pageTitle = "",
  children,
  headerItems,
  className = "",
  path,
  breadcrumbsLabel,
}) => {
  const { showMenu } = useContext(LayoutContext);
  const { toasts, setToasts } = useAuthContext();

  const { currentPage, activeModule } = useBreadcrumbs({
    path,
    pageTitle,
  });

  const { colorClass = "" } = activeModule;

  if (!sessionStorage?.loggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <div
      className={`layout layout-flex with-fixed-header ${
        showMenu ? "menuOpen" : ""
      } ${className}`}
      style={colorClass ? { "--module-color": `var(--${colorClass})` } : {}}
    >
      <PageHelmet
        pageTitle={currentPage?.title || pageTitle}
        moduleTitle={activeModule?.title}
      />
      <Header
        pageTitle={pageTitle}
        headerItems={headerItems}
        path={path}
        rightBlock={breadcrumbsLabel}
        colorClass={colorClass}
      />
      <SideMenu />
      <Container fluid className="g-0 container-main">
        {children}
        <Outlet />
      </Container>
      <FeedbackForm />
      <ToastHandler toasts={toasts} setToasts={setToasts} />
    </div>
  );
};

export default Layout;
