import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  FormSelect,
  InputGroup,
  ListGroup,
  OverlayTrigger,
  Row,
  Stack,
  Table,
  Tooltip,
} from "react-bootstrap";
import useFetch from "../../utils/useFetch";
import WithLoaderAndError from "../ui/WithLoaderAndError";
import PaginationFromHeader from "../ui/PaginationFromHeader";
import {
  BsArrowDownRight,
  BsArrowUpRight,
  BsCalendarWeekFill,
  BsCaretRight,
  BsCheckCircleFill,
  BsFillGridFill,
  BsFunnelFill,
  BsGrid3X3GapFill,
  BsImageFill,
  BsList,
  BsTable,
  BsX,
} from "react-icons/bs";
import { VscUngroupByRefType } from "react-icons/vsc";
import SearchBox from "../ui/SearchBox";
import NoResults from "../ui/NoResults";
import { Link, useParams } from "react-router-dom";
import ImgBox from "../ui/ImgBox";
import { GoPlus } from "react-icons/go";
import shouldCenterClass from "../../utils/shouldCenterClass";
import Moment from "react-moment";
import formatPrice from "../../utils/formatPrice";
import ItemCard from "./ItemCard";
import OutletRow from "../layout/OutletRow";
import { ACS, DEC } from "../../constants";
import useTotals from "../../utils/useTotals";
import useListPage from "../../utils/useListPage";
import Loader from "./Loader";
import CountButton from "./CountButton";
import { useLayout } from "../../context/LayoutContext";

const ListPage = ({
  baseUrl = "",
  urlParams = "",
  urlParamsObj = {},
  clientId,
  customerId,
  pageTitle = "",
  path,
  pageSizes = [5, 10, 15, 20, 30],
  orderOptions = ["id", "sequence"],
  searchParam = "query",
  directionParam = "direction",
  defaultDirection = ACS,
  itemLabel = "",
  itemPath = itemLabel,
  getItemLink = (item) => `/${itemPath}/${item.id}`,
  listItem: ListItem,
  listHeader: ListLayoutHeader,
  gridItem: GridItem,
  gridItemXL: GridItemXL,
  detailItem: DetailItem,
  gridColProps, //tuesday pass props through here
  gridColPropsXL,
  thumbnailCol = "thumbnailFileId",
  tableItems = {},
  headerItems = null,
  layoutOptions = ["table", "grid"],
  defaultLayout,
  searchProps = {},
  thumbIcon,
  showNewItemBtn = true,
  newItemBtn: NewItemBtn,
  toggleExpandedView,
  expandedItems = [], //only for Action Lists right now
  menuType,
  colorClass,
  group = "",
  storageKey,
  filters,
  onClear = () => {},
  filterCount = 0,
}) => {
  const [state, setValue] = useListPage(storageKey);
  const { showSidebar, setShowSidebar } = useLayout();

  // Initialize state if doesn't exist in context
  useEffect(() => {
    if (!state?.page) {
      const orderConfig = orderOptions?.length
        ? { orderBy: orderOptions?.[0], direction: defaultDirection }
        : {};
      setValue({
        page: 1,
        pageSize: 30,
        layout: defaultLayout || layoutOptions[0],
        ...orderConfig,
        query: "",
      });
    }
  }, [
    state?.page,
    defaultLayout,
    layoutOptions,
    orderOptions,
    defaultDirection,
    setValue,
  ]);

  const { page, pageSize, orderBy, direction, query, layout } = state;

  const setPage = (val) => setValue({ page: val });
  const setPageSize = (val) => setValue({ pageSize: val, page: 1 });
  const setOrderBy = (val) => setValue({ orderBy: val });
  const setDirection = (val) => setValue({ direction: val });
  const setQuery = (val) => setValue({ query: val, page: 1 });
  const setLayout = (val) => setValue({ layout: val });

  const [isGroupedBy, setIsGroupedBy] = useState(false);

  const dataUrl =
    baseUrl +
    `?page=${page}&pageSize=${pageSize}${
      orderOptions?.length
        ? `&orderBy=${orderBy}&${directionParam}=${direction}`
        : ``
    }${!!searchParam ? `&${searchParam}=${query}` : ""}` +
    urlParams;

  // const orderParams = orderOptions?.length
  //   ? { orderBy, [directionParam]: direction }
  //   : {};
  // const params = { page, pageSize, ...orderParams, ...urlParamsObj };

  const { itemId: selectedItemId } = useParams();

  const {
    data: items,
    headers,
    error,
  } = useFetch({
    url: dataUrl,
    // url: baseUrl,
    // params,
    clientId,
    customerId,
  });

  const { total, isExact } = useTotals({
    linkStr: headers?.link,
    page,
    pageSize,
    itemsCount: items?.length,
  });

  const groupBy = (arr, prop) => {
    if (arr && arr.length && prop) {
      const newArr = arr.reduce((acc, obj) => {
        let key = obj[prop]
          ? prop === "path"
            ? obj[prop].substring(1)
            : obj[prop]
          : "Other";
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
      return Object.entries(newArr).map(([key, items]) => ({ key, items }));
    } else {
      return [];
    }
  };

  const nestedObj = groupBy(items, group);
  const hasGroupKey = group !== "";

  const itemsSource = tableItems ? tableItems : items ? items?.[0] : [];

  const fields = itemsSource
    ? Object.keys(itemsSource).filter(
        (key) => itemsSource?.[key]?.hidden !== true
      )
    : [];

  // useEffect(() => {
  //   console.log("to page 1, setStorage changed");
  //   setStorage((curr) => ({ ...curr, page: 1 }));
  // }, [pageSize, orderBy, direction, query, setStorage]);

  // useEffect(() => {
  //   setPage(1);
  // }, [pageSize, orderBy, direction, query, setPage]);

  // useEffect(() => {
  //   // setPage(1);
  //   console.log("new setPage");
  // }, [setPage]);

  // useEffect(() => {
  //   return () => {
  //     setStored({ page, pageSize, orderBy, direction, query, layout });
  //   };
  // }, [page, pageSize, orderBy, direction, query, layout, setStored]);

  // const currentGridColProps =
  //   layout === "grid-xl"
  //     ? gridColPropsXL
  //       ? gridColPropsXL
  //       : { xs: 12, sm: 6, md: 6, lg: 6, xl: 4, xxl: 4, xxxl: 3 }
  //     : gridColProps
  //     ? gridColProps
  //     : { xs: 12, sm: 3, md: 3, lg: 2, xl: 2, xxl: 2, xxxl: 2 };
  const ListLayoutItem = ({ item }) =>
    !!ListItem ? (
      <ListItem
        item={item}
        to={getItemLink(item)}
        thumbIcon={thumbIcon}
        imgId={item?.[thumbnailCol]}
        isSelected={item?.id === +selectedItemId}
      />
    ) : (
      <ListGroup.Item
        as={Link}
        to={getItemLink(item)}
        action
        active={item?.id === +selectedItemId}
        className={`d-flex gap-3 align-items-center`}
      >
        <ImgBox
          imgId={item?.[thumbnailCol]}
          width={42}
          icon={thumbIcon}
          style={{ flexBasis: 42 }}
          className="flex-shrink-0 flex-grow-0 ms-n1"
        />

        <div className="text-truncate text-overflow" style={{ flexBasis: 350 }}>
          {item?.name}
        </div>

        <div
          className="flex-shrink-1 font-monospace opacity-75"
          style={{ flexBasis: 60 }}
        >
          {item?.id}
        </div>
      </ListGroup.Item>
    );

  const GridLayoutItem = ({ item }) =>
    !!GridItem ? (
      <GridItem item={item} />
    ) : (
      <ItemCard {...item} itemLink={getItemLink(item)} />
    );

  const GridXLLayoutItem = ({ item }) =>
    !!GridItemXL ? <GridItemXL item={item} /> : <GridLayoutItem item={item} />;

  const TableHeadCell = ({
    itemKey: key,
    displayHeading,
    headingComponent: HeadingComponent,
    cellType,
    cellClass = cellType === "text" ||
    key.toLowerCase().indexOf("name") > -1 ||
    key.toLowerCase().indexOf("city") > -1 ||
    key === "description" ||
    key === "title" ||
    key === "tooltip" ||
    cellType === "date" ||
    key === "modified" ||
    key.toLowerCase().indexOf("date") > -1
      ? ""
      : "text-center",
  }) => {
    return !!HeadingComponent ? (
      <th className={`${cellClass} text-nowrap`}>
        <OverlayTrigger
          overlay={<Tooltip id={`tooltipth${key}`}>{key}</Tooltip>}
        >
          <span>
            <HeadingComponent />
          </span>
        </OverlayTrigger>
      </th>
    ) : !!displayHeading ? (
      <th className={`${cellClass} text-nowrap`}>
        <OverlayTrigger
          overlay={<Tooltip id={`tooltipth${key}`}>{key}</Tooltip>}
        >
          <span>{displayHeading}</span>
        </OverlayTrigger>
      </th>
    ) : cellType === "thumbnail" || key === thumbnailCol ? (
      <th className={`${cellClass} text-nowrap`}>
        <OverlayTrigger
          overlay={<Tooltip id={`tooltipth${key}`}>{key}</Tooltip>}
        >
          <span>
            <BsImageFill className="text-secondary-tint-15" />
          </span>
        </OverlayTrigger>
      </th>
    ) : cellType === "date" ||
      key === "modified" ||
      key.toLowerCase().indexOf("date") > -1 ? (
      <th className={`${cellClass} text-nowrap`}>
        <OverlayTrigger
          overlay={<Tooltip id={`tooltipth${key}`}>{key}</Tooltip>}
        >
          <span>
            <BsCalendarWeekFill className="text-secondary-tint-15" />
          </span>
        </OverlayTrigger>
      </th>
    ) : (
      <th className={`${cellClass} text-nowrap`}>
        <span className={key === "id" ? "text-uppercase" : "text-capitalize"}>
          {key}
        </span>
      </th>
    );
  };

  const TableCell = ({
    itemKey: key,
    value,
    extraValue,
    component: Component,
    cellType,
    cellClass = "",
    cellWidth = cellType === "thumbnail" ? 46 : 200,
    cellPadding,
    itemLink,
    bringToFront = false,
    itemId, //is currently only used for expanded description in action lists
  }) => {
    const linkOverlay = <Link to={itemLink} className="table-cell-link" />;

    const isExpanded = expandedItems.includes(itemId);
    const handleClick = () => {
      toggleExpandedView(itemId);
    };

    return !!Component ? (
      <td
        style={{
          width: cellWidth || "auto",
          padding: cellPadding || "inherit",
          whiteSpace: "normal",
        }}
        className={cellClass}
      >
        {bringToFront ? linkOverlay : null}
        <Component value={value} extraValue={extraValue} />
        {!bringToFront ? linkOverlay : null}
      </td>
    ) : cellType === "thumbnail" || key === thumbnailCol ? (
      <td
        style={{ width: cellWidth || "46px", padding: cellPadding || 0 }}
        className={cellClass}
      >
        {bringToFront ? linkOverlay : null}
        <ImgBox
          imgId={value}
          width={46}
          noStretch
          className="fade-in-grow delay m-n2"
          icon={thumbIcon}
        />
        {!bringToFront ? linkOverlay : null}
      </td>
    ) : cellType === "id" ||
      key === "id" ||
      key === "programId" ||
      key === "customerId" ||
      key === "mailMomentId" ||
      key === "segmentId" ||
      key === "treatmentId" ||
      key === "minAmount" ||
      key === "minAmount" ||
      key === "stepSize" ||
      key === "sequence" ||
      key.toLowerCase().indexOf("code") > -1 ? (
      <td className={cellClass || "text-center"} style={{ width: "46px " }}>
        {bringToFront ? linkOverlay : null}
        <div className="font-monospace text-secondary-tint-25 text-nowrap fade-in delay">
          {value}
        </div>
        {!bringToFront ? linkOverlay : null}
      </td>
    ) : cellType === "text" ||
      key.toLowerCase().indexOf("name") > -1 ||
      key === "description" ||
      key === "title" ||
      key === "tooltip" ? (
      <td
        className={cellClass}
        style={cellWidth ? { maxWidth: cellWidth } : {}}
      >
        {bringToFront ? linkOverlay : null}
        <div className="text-truncate fade-in delay">{value}</div>
        {!bringToFront ? linkOverlay : null}
      </td>
    ) : cellType === "price" || key === "price" ? (
      <td
        className={cellClass}
        style={cellWidth ? { maxWidth: cellWidth } : {}}
      >
        {bringToFront ? linkOverlay : null}
        <div className="text-success-shade-10 font-monospace fade-in delay">
          {!!value ? formatPrice(value) : ""}
        </div>
        {!bringToFront ? linkOverlay : null}
      </td>
    ) : cellType === "date" ||
      key === "modified" ||
      key.toLowerCase().indexOf("date") > -1 ? (
      <td
        className={cellClass}
        style={cellWidth ? { maxWidth: cellWidth } : {}}
      >
        {bringToFront ? linkOverlay : null}
        {!!value ? (
          <Moment
            format="DD MMM YYYY"
            className="fade-in delay text-nowrap small text-muted"
          >
            {value}
          </Moment>
        ) : null}
        {!bringToFront ? linkOverlay : null}
      </td>
    ) : cellType === "expandbutton" ? ( //for
      <td style={cellWidth ? { maxWidth: cellWidth } : {}}>
        <Button
          className={"position-relative"}
          onClick={handleClick}
          style={{
            backgroundColor: "transparent",
            border: "none",
            padding: "0",
          }}
        >
          <BsCaretRight
            style={{
              color: isExpanded ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.2)",
              transform: isExpanded ? "rotate(90deg)" : "none",
            }}
          />
        </Button>
      </td>
    ) : (
      <td key={`td${key}`} className={cellClass}>
        {bringToFront ? linkOverlay : null}
        <div className="fade-in delay">
          {typeof value === "string" || typeof value === "number" || !value ? (
            value
          ) : !!value ? (
            <BsCheckCircleFill className="text-success" />
          ) : (
            "false"
          )}
        </div>
        {!bringToFront ? linkOverlay : null}
      </td>
    );
  };

  const TableRow = ({ item, isSelected }) => (
    <tr key={item.id} className={isSelected ? "table-active" : ""}>
      {fields.flatMap((key) => [
        <TableCell
          key={`${item.id}-${key}`}
          itemId={item.id}
          itemKey={key}
          itemLink={getItemLink(item)}
          value={
            key.indexOf(".") > -1
              ? item?.[key.split(".")?.[0]]?.[key.split(".")?.[1]]
              : item?.[key]
          }
          extraValue={item?.[tableItems?.[key]?.mergeWith] || null}
          {...tableItems?.[key]}
        />,
      ])}
    </tr>
  );

  const LayoutOptionIcon = ({ option }) =>
    option === "table" ? (
      <BsTable />
    ) : option === "grid" ? (
      <BsGrid3X3GapFill />
    ) : option === "list" ? (
      <BsList />
    ) : option === "grid-xl" ? (
      <BsFillGridFill />
    ) : option === "detail" ? (
      <BsFillGridFill />
    ) : (
      <BsX title="Unsupported layout" />
    );
  return (
    <Layout
      pageTitle={pageTitle}
      path={path}
      className={`list-page ${selectedItemId ? " outlet-open" : ""}`}
    >
      {!page ? (
        <Loader fullPage />
      ) : (
        <OutletRow
          filterCol={
            <Card
              body
              className="h-100 bg-body text-body w-100"
              data-bs-theme="dark"
            >
              {filters}
            </Card>
          }
        >
          <Card className="border-0 card-main">
            {!!headerItems ||
            layoutOptions?.length > 1 ||
            !!searchParam ||
            !!showNewItemBtn ||
            ListLayoutHeader ? (
              <Card.Header className="px-2 bg-gray-200 toolbar-top">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="justify-content-end flex-wrap"
                >
                  <CountButton
                    // count: number of active filter
                    // onClear: function to unset active filters
                    count={filterCount}
                    onClear={() => {
                      onClear();
                      setShowSidebar(false); // Ensure Sidebar closes after clearing
                    }}
                    onClick={() => setShowSidebar((curr) => !curr)}
                    active={showSidebar}
                  >
                    <BsFunnelFill />
                  </CountButton>
                  <span className="flex-grow-1"></span>
                  {!selectedItemId ? headerItems : null}
                  {hasGroupKey && !selectedItemId && (
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => setIsGroupedBy(!isGroupedBy)}
                      disabled={nestedObj.length < 2}
                    >
                      <VscUngroupByRefType style={{ fontSize: "1.3em" }} />
                    </Button>
                  )}
                  {layoutOptions.length > 1 && !selectedItemId ? (
                    <ButtonGroup size="sm">
                      {layoutOptions.map((option) => (
                        <Button
                          key={option}
                          variant="secondary"
                          active={layout === option}
                          onClick={() => setLayout(option)}
                        >
                          <LayoutOptionIcon option={option} />
                        </Button>
                      ))}
                    </ButtonGroup>
                  ) : null}

                  {!!searchParam ? (
                    <SearchBox
                      query={query}
                      setQuery={setQuery}
                      size="sm"
                      className="w-auto"
                      disabled={!!selectedItemId}
                      {...searchProps}
                    />
                  ) : null}

                  {showNewItemBtn ? (
                    !!NewItemBtn ? (
                      NewItemBtn
                    ) : (
                      <Button
                        size="sm"
                        variant="success"
                        as={Link}
                        to={`/${itemPath}/new`}
                      >
                        <GoPlus className="btn-icon" /> New
                      </Button>
                    )
                  ) : null}
                </Stack>
              </Card.Header>
            ) : null}

            <Card.Body
              className={`${shouldCenterClass(
                (!items?.length && !error) || !!error
              )} ${
                layout === ("grid" || "grid-xl")
                  ? "p-2 extra-card-padding d-flex justify-content-center align-items-start"
                  : "p-0"
              } scrollbar-thin`}
            >
              <WithLoaderAndError isLoading={!items && !error} error={error}>
                {items && !!items.length ? (
                  layout === "list" ? (
                    <>
                      {ListLayoutHeader ? (
                        <ListGroup variant="flush" className="w-100">
                          <ListLayoutHeader
                            orderOptions={orderOptions}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            direction={direction}
                            setDirection={setDirection}
                          />
                        </ListGroup>
                      ) : null}
                      <ListGroup
                        variant="flush"
                        className="w-100 h-100 short-delay-parent list-layout striped"
                      >
                        {items.map((item) => (
                          <ListLayoutItem item={item} key={item.id} />
                        ))}
                      </ListGroup>
                    </>
                  ) : layout === "table" ? (
                    <div
                      style={{
                        display: "inline-block",
                        minWidth: "100%",
                        width: "500px",
                      }}
                    >
                      <Table responsive striped hover className="mb-0">
                        <thead>
                          <tr>
                            {fields.map((key) => (
                              <TableHeadCell
                                key={`th${key}`}
                                itemKey={key}
                                displayHeading={
                                  tableItems?.[key]?.displayHeading
                                }
                                headingComponent={
                                  tableItems?.[key]?.headingComponent
                                }
                                cellClass={
                                  tableItems?.[key]?.thClass ||
                                  tableItems?.[key]?.cellClass
                                }
                                cellType={tableItems?.[key]?.cellType}
                              />
                            ))}
                          </tr>
                        </thead>
                        <tbody className="short-delay-parent">
                          {isGroupedBy
                            ? nestedObj.map((group, index) => (
                                <React.Fragment key={`group-${group.key}`}>
                                  {nestedObj.length === 1 ||
                                  nestedObj[index].key === "" ? null : (
                                    <tr key={`group-header-${group.key}`}>
                                      <td colSpan="100%">
                                        <strong style={{ marginTop: "60px" }}>
                                          {group.key}
                                        </strong>{" "}
                                        {/* This prints the group's key */}
                                      </td>
                                    </tr>
                                  )}
                                  {group.items.map((item) => (
                                    <React.Fragment key={`fragment-${item.id}`}>
                                      <TableRow
                                        item={item}
                                        key={`tli-${item.id}`}
                                      />
                                      {expandedItems.includes(item.id) && (
                                        <tr key={`expanded-${item.id}`}>
                                          <td colSpan="100%">
                                            <strong>Description</strong>{" "}
                                            <div>{item.description}</div>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ))
                            : items.map((item) => (
                                <React.Fragment key={`fragment-${item.id}`}>
                                  <TableRow
                                    item={item}
                                    key={`tli${item.id}`}
                                    isSelected={item?.id === +selectedItemId}
                                  />
                                  {expandedItems.includes(item.id) && (
                                    <tr key={`expanded-${item.id}`}>
                                      <td colSpan="100%">
                                        <strong>Description</strong>{" "}
                                        <div>{item.description}</div>{" "}
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : layout === "grid" || layout === "grid-xl" ? (
                    <Row
                      className={`g-2 g-md-3 g-xl-4 w-100 ${
                        layout === "grid" || layout === "grid-xl"
                          ? "short-"
                          : ""
                      }delay-parent align-content-start`}
                      xs={1}
                      sm={selectedItemId ? 1 : 2}
                      md={selectedItemId ? 1 : 3}
                      lg={selectedItemId ? 1 : 4}
                      xl={selectedItemId ? 1 : 4}
                      xxl={selectedItemId ? 1 : 6}
                      xxxl={selectedItemId ? 1 : 12}
                      style={{
                        "--bs-gutter-x": "var(--custom-row-gutter-x)",
                        "--bs-gutter-y": "var(--bs-gutter-x)",
                      }}
                    >
                      {isGroupedBy
                        ? nestedObj.map((group) => (
                            <React.Fragment key={`group-${group.key}`}>
                              {nestedObj.length === 1 &&
                              nestedObj[0].key === "" ? null : (
                                <strong>{group.key}</strong>
                              )}
                              {group.items.map((item) => {
                                return (
                                  <Col
                                    // {...combinedColProps}
                                    key={item.id}
                                  >
                                    {layout === "grid" ? (
                                      <GridLayoutItem item={item} />
                                    ) : (
                                      <GridXLLayoutItem item={item} />
                                    )}
                                  </Col>
                                );
                              })}
                            </React.Fragment>
                          ))
                        : items.map((item) => {
                            return (
                              <Col key={item.id}>
                                {layout === "grid" ? (
                                  <GridLayoutItem item={item} />
                                ) : (
                                  <GridXLLayoutItem item={item} />
                                )}
                              </Col>
                            );
                          })}
                    </Row>
                  ) : layout === "detail" ? (
                    <Container fluid className="mb-0">
                      <Row className="g-2">
                        {items.map((item) => {
                          return (
                            <Col key={item.id} xl={6}>
                              <DetailItem item={item} />
                            </Col>
                          );
                        })}
                      </Row>
                    </Container>
                  ) : (
                    "Unsupported layout"
                  )
                ) : (
                  <div className="d-flex justify-content-center align-items-center no-results-wrap">
                    {query ? (
                      <NoResults>
                        <div className="text-muted mt-2">
                          <div className="mb-3">
                            No {itemLabel}s match{" "}
                            <span className="text-teal font-monospace">
                              "{query}"
                            </span>
                          </div>
                          {query ? (
                            <Button
                              variant="outline-primary"
                              onClick={() => setQuery("")}
                            >
                              <BsX /> Clear search
                            </Button>
                          ) : null}
                        </div>
                      </NoResults>
                    ) : (
                      <NoResults>
                        <div className="text-muted mt-2">
                          <div className="mb-3">No {itemLabel}s found</div>
                        </div>
                      </NoResults>
                    )}
                  </div>
                )}
              </WithLoaderAndError>
            </Card.Body>

            {pageSizes?.length || !!headers?.link || !!orderOptions?.length ? (
              <Card.Footer className="px-0 bg-gray-200">
                <Container fluid className="px-2">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="flex-wrap justify-content-between"
                  >
                    {pageSizes?.length && !selectedItemId ? (
                      <Stack gap={2} direction="horizontal" className="w-auto">
                        <FormSelect
                          aria-label="Page size"
                          defaultValue={pageSize}
                          onChange={(e) => setPageSize(+e.target.value)}
                          size="sm"
                          className="w-auto"
                        >
                          {pageSizes.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        </FormSelect>
                        <span className="small d-inline-block">per page</span>
                      </Stack>
                    ) : null}

                    {!!headers?.link ? (
                      <PaginationFromHeader
                        current={page}
                        linkStr={headers.link}
                        setPage={setPage}
                        showButtons={!selectedItemId}
                        pageSize={pageSize}
                      />
                    ) : null}

                    <div className="me-4 small">
                      {total}
                      {isExact ? "" : "+"} result{total % 10 === 1 ? "" : "s"}
                    </div>

                    {!selectedItemId ? (
                      orderOptions ? (
                        <Stack
                          gap={2}
                          direction="horizontal"
                          className="w-auto"
                        >
                          <span className="small">Sort by</span>
                          <InputGroup
                            size="sm"
                            className="w-auto align-items-center"
                          >
                            <FormSelect
                              aria-label="Sort by"
                              defaultValue={orderBy}
                              onChange={(e) => setOrderBy(e.target.value)}
                            >
                              {orderOptions.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            </FormSelect>
                            <Button
                              variant="light"
                              onClick={() =>
                                setDirection((curr) =>
                                  curr === ACS ? DEC : ACS
                                )
                              }
                              title={direction}
                            >
                              {direction === ACS ? (
                                <BsArrowUpRight title={ACS} />
                              ) : (
                                <BsArrowDownRight title={DEC} />
                              )}
                            </Button>
                          </InputGroup>
                        </Stack>
                      ) : (
                        <div style={{ minWidth: "10%" }}></div>
                      )
                    ) : null}
                  </Stack>
                </Container>
              </Card.Footer>
            ) : null}
          </Card>
        </OutletRow>
      )}
    </Layout>
  );
};

export default ListPage;
