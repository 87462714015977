import { Button, Card, Modal, Table } from "react-bootstrap";
import SenderApprovalWrap from "./SenderApprovalWrap";
import PaginationFromHeader from "../../../ui/PaginationFromHeader";

const SenderApprovalButton = ({ customerId }) => {
  return (
    <SenderApprovalWrap
      customerId={customerId}
      component={({
        showModal,
        handleShowModal,
        handleCloseModal,
        treatments,
        treatmentsLink,
        contactPersons,
        contactPersonsLink,
        handleSelectTreatment,
        handleSendApproval,
        handleSelectContactPersons,
        selectedTreatment,
        selectedContactPersons,
        pageSize,
        contactPersonsPage,
        treatmentPage,
        setTreatmentPage,
        setContactPersonsPage,
      }) => (
        <>
          <Button
            className="w-100"
            variant="secondary"
            onClick={handleShowModal}
            disabled={treatments?.length === 0}
          >
            Send Reminder
          </Button>
          <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>Send Approval Reminders</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "auto", maxHeight: 450 }}>
              <Card>
                <Card.Header>
                  <Card.Title>Select Treatment</Card.Title>
                </Card.Header>
                <Card.Body className="p-0">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Language</th>
                      </tr>
                    </thead>
                    <tbody>
                      {treatments?.map((treatment) => (
                        <tr
                          onClick={() => handleSelectTreatment(treatment)}
                          key={treatment?.id}
                          style={{ cursor: "pointer" }}
                        >
                          <td
                            style={
                              selectedTreatment?.id === treatment.id
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            {treatment.name}
                          </td>
                          <td
                            style={
                              selectedTreatment?.id === treatment.id
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            {treatment.description}
                          </td>
                          <td
                            style={
                              selectedTreatment?.id === treatment.id
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            {treatment.languageCode}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>

                {treatmentsLink && (
                  <Card.Footer className="d-flex justify-content-center align-items-center">
                    <PaginationFromHeader
                      current={treatmentPage}
                      linkStr={treatmentsLink}
                      setPage={setTreatmentPage}
                      pageSize={pageSize}
                    />
                  </Card.Footer>
                )}
              </Card>
              <Card style={{ marginTop: 20 }}>
                <Card.Header>
                  <Card.Title>Select Contact Persons</Card.Title>
                </Card.Header>
                <Card.Body className="p-0">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contactPersons?.map((contactPerson) => (
                        <tr
                          key={contactPerson.id}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleSelectContactPersons(contactPerson)
                          }
                        >
                          <td
                            style={
                              selectedContactPersons?.some(
                                (selected) => selected.id === contactPerson.id
                              )
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            {contactPerson.firstName} {contactPerson.prefix}{" "}
                            {contactPerson.lastName}
                          </td>
                          <td
                            style={
                              selectedContactPersons?.some(
                                (selected) => selected.id === contactPerson.id
                              )
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            {contactPerson.contactDetails
                              .filter((detail) => detail.type === "Email")
                              .map((detail) => detail.value)
                              .join(", ")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>

                {!!contactPersonsLink && (
                  <Card.Footer className="d-flex justify-content-center align-items-center">
                    <PaginationFromHeader
                      current={contactPersonsPage}
                      linkStr={contactPersonsLink}
                      setPage={setContactPersonsPage}
                      pageSize={pageSize}
                    />
                  </Card.Footer>
                )}
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                variant="success"
                disabled={
                  selectedContactPersons?.length === 0 || !selectedTreatment
                }
                onClick={handleSendApproval}
              >
                Send Reminder
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    />
  );
};
export default SenderApprovalButton;
