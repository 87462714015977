import React, { useContext, useState } from "react";

const StorageContext = React.createContext();
export const useStorage = () => useContext(StorageContext);

// use useStateWithStorage to duplicate in localStorage

export const StorageProvider = ({ children }) => {
  const [customer, setCustomer] = useState(null);

  const customersStore = useState({});

  const variantsStore = useState({});

  return (
    <StorageContext.Provider
      value={{
        customer,
        setCustomer,

        // list pages
        customersStore,
        variantsStore,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};

export default useStorage;
