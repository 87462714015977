import React from "react";
import { Col, ListGroupItem, Row, Stack } from "react-bootstrap";
import { BsArrowRight, BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";
import ImgBox from "./ImgBox";
import camelCaseToText from "../../utils/camelCaseToText";

// child of ListGroup flush
const LabeledItem = ({
  label: initLabel,
  name,
  id,
  thumbnailFileId,
  editable = false,
  onClick,
  to,
  className = "",
  fixLabel = false,
  matchInputPadding = true,
  minHeight = "1.25em",
}) => {
  const isLink = to && !editable;
  const isAction = editable && onClick;
  const isDisabled = editable && !onClick;
  const renderAs = isLink
    ? { as: Link, to }
    : isAction
    ? { type: "button", onClick }
    : { as: "div" };
  const label = fixLabel ? camelCaseToText(initLabel) : initLabel;
  return (
    <ListGroupItem
      action={isLink || isAction}
      {...renderAs}
      className={"hover-parent match-floating-input " + className}
    >
      <Row className="g-0 align-items-center">
        {thumbnailFileId ? (
          <Col
            sm="auto"
            style={{ width: "57px", height: "57px", background: "#ddd" }}
          >
            <ImgBox imgId={thumbnailFileId} width={57} />
          </Col>
        ) : null}
        <Col
          className={`inner flex-grow-1 flex-shrink-0 ${
            matchInputPadding ? "" : "p-0"
          }`}
        >
          <label className="small text-muted d-block pointer-events-none">
            {label}
          </label>
          <Stack
            direction="horizontal"
            gap={2}
            className={
              (isDisabled ? "opacity-50" : "") +
              " justify-content-between align-items-end"
            }
            style={{ minHeight: minHeight }}
          >
            {id ? (
              <div className="fs-75 font-monospace text-muted">{id}</div>
            ) : null}
            <div className="flex-grow-1">{name}</div>
          </Stack>
        </Col>
        <Col sm="auto">
          <span className="hover-opacity pe-4 ps-2 text-secondary">
            {isLink ? (
              <BsArrowRight />
            ) : isAction ? (
              <BsPencil />
            ) : (
              <BsPencil style={{ visibility: "hidden" }} />
            )}
          </span>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default LabeledItem;
