import SearchBox from "../../../ui/SearchBox";
import TreatmentsWrap from "../../Wraps/TreatmentsWrap";
import { Form, ListGroup } from "react-bootstrap";
import PaginationFromHeader from "../../../ui/PaginationFromHeader";

const TreatmentsListGroup = ({ treatments, setTreatments }) => {
  return (
    <TreatmentsWrap
      treatments={treatments}
      setTreatments={setTreatments}
      component={({
        allTreatments,
        handleClick,
        page,
        setPage,
        PAGE_SIZE,
        searchQuery,
        setSearchQuery,
        headers,
      }) => (
        <>
          <SearchBox
            className="w-100"
            placeholder="Search Treatments"
            query={searchQuery}
            setQuery={setSearchQuery}
            maxWidth={600}
            size="md"
            clearSearchStyle={{
              position: "absolute",
              zIndex: "999",
              top: "7px",
              left: "575px",
              color: "red",
            }}
          />

          <ListGroup
            className="scrollbar-thin"
            style={{
              "--bs-list-group-active-bg": "#4b626b",
              "--bs-list-group-active-border-color": "#4b626b",
              maxHeight: 350,
              marginTop: 10,
              overflowY: "auto",
              paddingTop: 10,
              cursor: "pointer",
            }}
          >
            {!!allTreatments && allTreatments.length > 0 ? (
              allTreatments.map((item) => (
                <ListGroup.Item
                  key={item.id}
                  active={treatments?.some(
                    (selected) => selected.id === item.id
                  )}
                  onClick={() => handleClick(item)}
                >
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <span>{item.name}</span>
                    <Form.Check
                      type="checkbox"
                      checked={treatments?.some(
                        (selected) => selected.id === item.id
                      )}
                      readOnly
                      className="me-2"
                    />
                  </div>
                </ListGroup.Item>
              ))
            ) : (
              <span
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: 100 }}
              >
                No Treatment found
              </span>
            )}
          </ListGroup>
          {!!headers?.link ? (
            <div
              className="d-flex justify-content-center"
              style={{ paddingTop: 10 }}
            >
              <PaginationFromHeader
                current={page}
                linkStr={headers.link}
                setPage={setPage}
                showButtons
                pageSize={PAGE_SIZE}
              />
            </div>
          ) : null}
        </>
      )}
    />
  );
};

export default TreatmentsListGroup;
