import React from "react";
import ProgramWrap from "../../Wraps/ProgramWrap";
import { Badge, ListGroup } from "react-bootstrap";
import SearchBox from "../../../ui/SearchBox";
import PaginationFromHeader from "../../../ui/PaginationFromHeader";
import { BsCheck2, BsMegaphoneFill } from "react-icons/bs";
import ImgBox from "../../../ui/ImgBox";
import CycleIcon from "../../../custom/CycleIcon";

const ProgramListGroup = ({
  program,
  setProgram,
  setMailMoment,
  programType,
}) => {
  return (
    <ProgramWrap
      program={program}
      setProgram={setProgram}
      setMailMoment={setMailMoment}
      programType={programType}
      component={({
        program,
        programs,
        handleClick,
        page,
        setPage,
        PAGE_SIZE,
        searchQuery,
        setSearchQuery,
        headers,
      }) => (
        <>
          <SearchBox
            className="w-100"
            placeholder={
              programType === "cycle"
                ? "Search Cycle"
                : programType === "campaign"
                ? "Search Campaign"
                : "Search Program"
            }
            query={searchQuery}
            setQuery={setSearchQuery}
            maxWidth={600}
            size="md"
            clearSearchStyle={{
              position: "absolute",
              zIndex: "999",
              top: "7px",
              left: "575px",
              color: "red",
            }}
          />

          <ListGroup
            className="scrollbar-thin"
            style={{
              "--bs-list-group-active-bg": "#4b626b",
              "--bs-list-group-active-border-color": "#4b626b",
              maxHeight: 350,
              marginTop: 10,
              overflowY: "auto",
              paddingTop: 10,
              cursor: "pointer",
            }}
          >
            {!!programs && programs.length > 0 ? (
              programs.map((item) => (
                <ListGroup.Item
                  active={program?.id === item.id}
                  key={item.id}
                  action
                  onClick={() => handleClick(item)}
                >
                  <div
                    className="d-flex justify-content-between w-100"
                    style={{ paddingLeft: 3, paddingRight: 3 }}
                  >
                    {/* LEFT SIDE */}
                    <div className="w-100 d-flex align-items-center">
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                        className="border-end me-2 overflow-hidden d-flex align-items-center"
                      >
                        <ImgBox
                          className="d-flex align-items-center"
                          imgId={item?.logoFileId}
                          width={48}
                          icon={
                            programType === "cycle"
                              ? CycleIcon
                              : BsMegaphoneFill
                          }
                          imgBg="secondary"
                          resizeMode="contain"
                          style={{ width: 48, height: "100%" }}
                        />
                      </div>
                      <div className="lh-sm" style={{ fontSize: "15px" }}>
                        <div className="fw-semibold">{item.name}</div>
                        <small className="text-muted font-monospace">
                          <Badge
                            style={{
                              backgroundColor:
                                programType === "campaign"
                                  ? "#6b598b"
                                  : "green",
                              color: "white",
                            }}
                            className={`${
                              program?.id === item.id ? "" : "hover-opacity-0"
                            } text-light text-opacity-75 font-monospace me-2 ms-n1`}
                          >
                            {item.id}
                          </Badge>
                        </small>
                      </div>
                    </div>

                    {/* RIGHT SIDE */}
                    <div className="w-100 d-flex justify-content-end align-items-center">
                      {program?.id === item.id && (
                        <BsCheck2 className="text-success ms-2" />
                      )}
                    </div>
                  </div>
                </ListGroup.Item>
              ))
            ) : (
              <span
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: 100 }}
              >
                No Program found
              </span>
            )}
          </ListGroup>
          {!!headers?.link ? (
            <div
              className="d-flex justify-content-center"
              style={{ paddingTop: 10 }}
            >
              <PaginationFromHeader
                current={page}
                linkStr={headers.link}
                setPage={setPage}
                showButtons
                pageSize={PAGE_SIZE}
              />
            </div>
          ) : null}
        </>
      )}
    />
  );
};

export default ProgramListGroup;
