import React from "react";
import { ListGroup } from "react-bootstrap";
import { BsCheck2 } from "react-icons/bs";
import camelCaseToText from "../../../../utils/camelCaseToText";

const ApprovalListGroupFilter = ({ approvalFilter, setApprovalFilter }) => {
  const OPTIONS = [
    "customerApproved",
    "approved",
    "allApproved",
    "pendingCustomerApproval",
    "pendingApproval",
    "pendingAllApproval",
    "pendingAnyApproval",
    "pendingOnlyCustomerApproval",
  ];
  return (
    <ListGroup
      className="scrollbar-thin"
      style={{
        "--bs-list-group-active-bg": "#4b626b",
        "--bs-list-group-active-border-color": "#4b626b",
        maxHeight: 300,
        marginTop: 10,
        overflowY: "auto",
        paddingTop: 10,
        cursor: "pointer",
      }}
    >
      {OPTIONS.map((option) => (
        <ListGroup.Item
          key={option}
          active={approvalFilter === option}
          onClick={() =>
            setApprovalFilter((curr) => (curr === option ? "" : option))
          }
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="fw-semibold">{camelCaseToText(option)}</div>
            {approvalFilter === option && <BsCheck2 className="text-success" />}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default ApprovalListGroupFilter;
