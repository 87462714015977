import { Button, Modal, Table } from "react-bootstrap";
import LabeledItem from "../../../ui/LabeledItem";
import MailMomentsWrap from "../../Wraps/MailMomentsWrap";

const EditMailMoment = ({
  customerId,
  programId,
  variantId,
  mailMoment,
  editable,
  onSubmit,
  variant,
  watch,
}) => {
  return (
    <MailMomentsWrap
      customerId={customerId}
      programId={programId}
      variantId={variantId}
      component={({
        mailMoments,
        show,
        handleOpen,
        handleCancel,
        selectedMailMoment,
        setSelectedMailMoment,
      }) => (
        <>
          <LabeledItem
            editable={editable}
            onClick={handleOpen}
            label="MailMoment"
            {...mailMoment}
            className="mb-3"
          />
          <Modal size="lg" centered show={show} onHide={handleCancel}>
            <Modal.Header>
              <Modal.Title>Update Mailmoment</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 400, overflowY: "auto" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {mailMoments.map((item) => (
                    <tr
                      key={item.id}
                      onClick={() => setSelectedMailMoment(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <td
                        style={
                          selectedMailMoment?.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.id}
                      </td>
                      <td
                        style={
                          selectedMailMoment?.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="success"
                disabled={!selectedMailMoment}
                onClick={() => {
                  handleCancel();
                  onSubmit({
                    mailMomentId: selectedMailMoment?.id,
                    segmentId: variant?.segment?.id,
                    name: watch("name"),
                    description: watch("description"),
                    validTerm: watch("validTerm"),
                    active: variant.active,
                  });
                }}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    />
  );
};
export default EditMailMoment;
