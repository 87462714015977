import { useEffect, useState } from "react";
import { config } from "../../../config";
import useFetch from "../../../utils/useFetch";

const ProgramWrap = ({
  program,
  setProgram,
  setMailMoment,
  component: Component,
  programType,
}) => {
  const { api } = config;
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;
  const [searchQuery, setSearchQuery] = useState("");

  const params = {
    page: page,
    pageSize: PAGE_SIZE,
    query: searchQuery,
    type: programType,
  };

  const dataUrl = `${api.phoenix}/programs`;

  const { data: programs, headers } = useFetch({
    url: dataUrl,
    params: params,
  });

  const handleClick = (selectedProgram) => {
    if (selectedProgram.id === program?.id) {
      setProgram(null);
    } else {
      setProgram(selectedProgram);
    }
    setMailMoment(null);
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  return (
    <Component
      program={program}
      programs={programs}
      handleClick={handleClick}
      page={page}
      setPage={setPage}
      PAGE_SIZE={PAGE_SIZE}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      headers={headers}
    />
  );
};

export default ProgramWrap;
