import React from "react";
import { Offcanvas, Button, Accordion, ButtonGroup } from "react-bootstrap";
import VariantFilterWrap from "../../Wraps/VariantFilterWrap";
import { BsFunnelFill, BsX } from "react-icons/bs";
import TreatmentsListGroup from "./TreatmentsListGroup";
import MailMomentListGroup from "./MailMomentListGroup";
import CustomersListGroup from "./CustomersListGroup";
import ProgramListGroup from "./ProgramListGroup";
import ActiveFilter from "./ActiveFilter";
import ApprovalListGroupFilter from "./ApprovalListGroupFilter";

const VariantFilterMenu = ({
  active,
  setActive,
  approvalFilter,
  setApprovalFilter,
  program,
  setProgram,
  customers,
  setCustomers,
  treatments,
  setTreatments,
  mailMoment,
  setMailMoment,
}) => {
  return (
    <VariantFilterWrap
      program={program}
      setProgram={setProgram}
      customers={customers}
      setCustomers={setCustomers}
      treatments={treatments}
      setTreatments={setTreatments}
      mailMoment={mailMoment}
      setMailMoment={setMailMoment}
      component={({ show, handleOpen, handleClose, handleClear }) => {
        return (
          <>
            <ButtonGroup size="sm">
              <Button
                size="sm"
                variant={
                  !!program ||
                  (!!treatments && treatments.length > 0) ||
                  !!mailMoment ||
                  (!!customers && customers.length > 0)
                    ? "secondary"
                    : "outline-secondary"
                }
                onClick={handleOpen}
              >
                <BsFunnelFill style={{ marginRight: 5 }} /> Filter Variants
              </Button>
              {(!!program ||
                (!!treatments && treatments.length > 0) ||
                !!mailMoment ||
                (!!customers && customers.length > 0)) && (
                <Button variant="danger" onClick={handleClear}>
                  <BsX />
                </Button>
              )}
            </ButtonGroup>

            <Offcanvas
              show={show}
              onHide={handleClose}
              style={{ width: "700px" }}
              data-bs-theme="dark"
            >
              <Offcanvas.Header closeButton closeVariant="white">
                <Offcanvas.Title>Filter Menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body
                className="d-flex flex-column"
                style={{ overflow: "hidden", maxHeight: "100dvh" }}
              >
                <Accordion
                  className="flex-grow-1 overflow-auto"
                  style={{
                    "--bs-accordion-active-bg": "rgba(75, 98, 107, 0.15)",
                    "--bs-accordion-active-color": "white",
                    "--bs-accordion-btn-icon": "white",

                    "--bs-accordion-btn-focus-border-color": "none",
                    "--bs-accordion-btn-focus-box-shadow": "none",
                  }}
                >
                  {/* CUSTOMERS */}
                  <Accordion.Item
                    className="w-100"
                    eventKey="0"
                    style={{
                      borderLeft:
                        !!customers && customers?.length > 0
                          ? "4px solid #00994d"
                          : "4px solid transparent",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Accordion.Header className="w-100">
                        Customers
                      </Accordion.Header>
                      {!!customers && customers.length > 0 ? (
                        <Button
                          variant="outline-danger"
                          style={{ border: 0, height: "50px" }}
                          onClick={() => setCustomers([])}
                          size="sm"
                        >
                          <BsX color="white" />
                        </Button>
                      ) : (
                        <div style={{ width: 35 }}></div>
                      )}
                    </div>

                    <Accordion.Body
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <CustomersListGroup
                        customers={customers}
                        setCustomers={setCustomers}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* TREATMENTS */}
                  <Accordion.Item
                    eventKey="3"
                    style={{
                      borderLeft:
                        !!treatments && treatments?.length > 0
                          ? "4px solid #00994d"
                          : "4px solid transparent",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Accordion.Header className="w-100">
                        Treatments
                      </Accordion.Header>
                      {!!treatments && treatments.length > 0 ? (
                        <Button
                          variant="outline-danger"
                          style={{ border: 0, height: "50px" }}
                          onClick={() => setTreatments([])}
                          size="sm"
                        >
                          <BsX color="white" />
                        </Button>
                      ) : (
                        <div style={{ width: 35 }}></div>
                      )}
                    </div>
                    <Accordion.Body
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <TreatmentsListGroup
                        treatments={treatments}
                        setTreatments={setTreatments}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* PROGRAMS */}
                  <Accordion.Item
                    eventKey="1"
                    style={{
                      borderLeft:
                        !!program && program?.programType === "campaign"
                          ? "4px solid #00994d"
                          : "4px solid transparent",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Accordion.Header className="w-100">
                        Campaign
                      </Accordion.Header>
                      {!!program && program?.programType === "campaign" ? (
                        <Button
                          variant="outline-danger"
                          style={{ border: 0, height: "50px" }}
                          onClick={() => {
                            setProgram(null);
                            setMailMoment(null);
                          }}
                          size="sm"
                        >
                          <BsX color="white" />
                        </Button>
                      ) : (
                        <div style={{ width: 35 }}></div>
                      )}
                    </div>
                    <Accordion.Body
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <ProgramListGroup
                        programType="campaign"
                        program={program}
                        setMailMoment={setMailMoment}
                        setProgram={setProgram}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* PROGRAMS */}
                  <Accordion.Item
                    eventKey="2"
                    style={{
                      borderLeft:
                        !!program && program?.programType === "cycle"
                          ? "4px solid #00994d"
                          : "4px solid transparent",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Accordion.Header className="w-100">
                        Cycle
                      </Accordion.Header>
                      {!!program && program?.programType === "cycle" ? (
                        <Button
                          variant="outline-danger"
                          style={{ border: 0, height: "50px" }}
                          onClick={() => {
                            setProgram(null);
                            setMailMoment(null);
                          }}
                          size="sm"
                        >
                          <BsX color="white" />
                        </Button>
                      ) : (
                        <div style={{ width: 35 }}></div>
                      )}
                    </div>
                    <Accordion.Body
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <ProgramListGroup
                        programType="cycle"
                        program={program}
                        setProgram={setProgram}
                        setMailMoment={setMailMoment}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* MAIL MOMENTS */}
                  {!!program && (
                    <Accordion.Item
                      eventKey="4"
                      style={{
                        borderLeft: !!mailMoment
                          ? "4px solid #00994d"
                          : "4px solid transparent",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <Accordion.Header className="w-100">
                          MailMoment
                        </Accordion.Header>
                        {!!mailMoment ? (
                          <Button
                            variant="outline-danger"
                            style={{ border: 0, height: "50px" }}
                            onClick={() => setMailMoment(null)}
                            size="sm"
                          >
                            <BsX color="white" />
                          </Button>
                        ) : (
                          <div style={{ width: 35 }}></div>
                        )}
                      </div>
                      <Accordion.Body
                        className="d-flex flex-column"
                        style={{
                          backgroundColor: "rgba(75, 98, 107, 0.02)",
                          maxHeight: "60vh",
                          overflow: "hidden",
                        }}
                      >
                        <MailMomentListGroup
                          mailMoment={mailMoment}
                          setMailMoment={setMailMoment}
                          programId={program?.id}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  )}

                  {/* ACTIVE FILTER */}
                  <Accordion.Item
                    eventKey="5"
                    style={{
                      borderLeft: active
                        ? "4px solid #00994d"
                        : "4px solid transparent",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Accordion.Header className="w-100">
                        Active
                      </Accordion.Header>
                      {active ? (
                        <Button
                          style={{ border: 0, height: "50px" }}
                          variant="outline-danger"
                          onClick={() => setActive("")}
                          size="sm"
                        >
                          <BsX color="white" />
                        </Button>
                      ) : (
                        <div style={{ width: 35 }}></div>
                      )}
                    </div>
                    <Accordion.Body
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <div>
                        <ActiveFilter active={active} setActive={setActive} />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* APPROVAL FILTER */}
                  <Accordion.Item
                    eventKey="6"
                    style={{
                      borderLeft: !!approvalFilter
                        ? "4px solid #00994d"
                        : "4px solid transparent",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Accordion.Header className="w-100">
                        Approval Filter
                      </Accordion.Header>
                      {!!approvalFilter ? (
                        <Button
                          style={{ border: 0, height: "50px" }}
                          variant="outline-danger"
                          onClick={() => setApprovalFilter("")}
                          size="sm"
                        >
                          <BsX color="white" />
                        </Button>
                      ) : (
                        <div style={{ width: 35 }}></div>
                      )}
                    </div>
                    <Accordion.Body
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <div>
                        <ApprovalListGroupFilter
                          approvalFilter={approvalFilter}
                          setApprovalFilter={setApprovalFilter}
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Offcanvas.Body>
            </Offcanvas>
          </>
        );
      }}
    />
  );
};

export default VariantFilterMenu;
