import {
  BsCartFill,
  BsInboxes,
  BsMegaphoneFill,
  BsPuzzleFill,
  BsSpeedometer,
  BsTable,
} from "react-icons/bs";
import { FaStore } from "react-icons/fa6";
import CycleIcon from "../components/custom/CycleIcon";
import QSIcon from "../components/custom/QSIcon";

export const listPageItems = [
  {
    path: "customers",
    title: "Customers",
    icon: FaStore,
    colorClass: "customer",
  },
  {
    path: "campaigns",
    title: "Campaigns",
    icon: BsMegaphoneFill,
    colorClass: "campaign",
  },
  {
    path: "cycles",
    title: "Cycles",
    icon: CycleIcon,
    colorClass: "cycle",
  },
  {
    path: "orders",
    title: "Orders",
    icon: BsCartFill,
    colorClass: "order",
  },
  {
    path: "variants",
    title: "Variants",
    icon: FaStore,
    colorClass: "variant",
  },
  {
    path: "products",
    title: "Products",
  },
  {
    path: "packages",
    title: "Packages",
  },
  {
    path: "actionlist",
    title: "Action lists",
    icon: BsTable,
    colorClass: "actionList",
  },
  {
    path: "quicksight",
    title: "QuickSight",
    icon: QSIcon,
    colorClass: "quickSight",
  },
  {
    path: "productions",
    title: "Productions",
    icon: BsInboxes,
    // BsListCheck,
    colorClass: "dashboard",
  },
];

export const menuItems = [
  ...listPageItems,
  {
    path: "",
    title: "Dashboard",
    icon: BsSpeedometer,
    colorClass: "dashboard",
  },
  {
    path: "programs",
    title: "Programs",
    icon: BsPuzzleFill,
  },
];
