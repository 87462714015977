import React from "react";
import { ListGroup } from "react-bootstrap";
import { BsCheck2 } from "react-icons/bs";
import { BOOL_OPTIONS } from "../../../../constants";

const ActiveFilter = ({ active, setActive }) => {
  return (
    <ListGroup
      className="scrollbar-thin"
      style={{
        "--bs-list-group-active-bg": "#4b626b",
        "--bs-list-group-active-border-color": "#4b626b",
        maxHeight: 200,
        overflowY: "auto",
        paddingTop: 10,
        cursor: "pointer",
      }}
    >
      {BOOL_OPTIONS.map((option) => (
        <ListGroup.Item
          key={option.toString()}
          active={active === option}
          onClick={() => setActive((curr) => (curr === option ? "" : option))}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="fw-semibold">{option ? "true" : "false"}</div>
            {active === option && <BsCheck2 className="text-success" />}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default ActiveFilter;
