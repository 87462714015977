import React from "react";
import { Card, Stack, Tab, Nav } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { config } from "../../../config";
import useFetch from "../../../utils/useFetch";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import VariantEditable from "./General/VariantForm";
import VariantParameters from "./VariantParameters";
import VariantApproval from "./General/VariantApproval";
import VariantCustomerApproval from "./General/VariantCustomerApproval";
import VariantApprovalHistory from "./VariantApprovalHistory";
import VariantProofing from "./ProofPage/VariantProofing";
import CopyVariant from "./General/CopyVariant";
import { useAuthContext } from "../../../context/AuthContext";
import LinkToOrder from "./General/LinkToOrder";
import DeleteVariantButton from "./DeleteVariantButton";
import SenderApprovalButton from "./General/SenderApprovalButton";

const VariantDetail = () => {
  const { variantId } = useParams();
  const { client } = useAuthContext();

  const {
    data: variant,
    error: variantError,
    mutate: refreshVariants,
  } = useFetch({
    url: `${config.api.phoenix}/variants/${variantId}`,
    //variantId: variantId,
  });

  const {
    data: parameters,
    mutate: refreshParameters,
    error: parameterError,
  } = useFetch({
    url: `${config.api.phoenix}/variants/${variantId}/parameters`,
  });

  const { data: history, mutate: refreshHistory } = useFetch({
    url: `${config.api.phoenix}/variants/${variantId}/approvalhistory`,
  });

  const { data: proofHistory, mutate: refreshProofHistory } = useFetch({
    url: `${config.api.phoenix}/variantproofs?variantId=${variantId}`,
  });

  const { data: usedConsumerParameters } = useFetch({
    url: `${config.api.phoenix}/variants/${variantId}/usedconsumerparameters`,
  });

  const tabList = ["General", "Parameters", "Approval history", "Proof"];

  return (
    <Layout
      pageTitle={variant?.name || `Variant ${variantId}`}
      menuType="variants"
      colorClass="variant"
    >
      <WithLoaderAndError
        isLoading={!variant && !variantError}
        error={variantError || parameterError}
        fullpage
      >
        {/* Filter tab content */}
        <Tab.Container defaultActiveKey={tabList[0]}>
          <Card.Header
            className="border-bottom-0 background-transparent pb-0 pt-2 px-4 mt-1"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Nav variant="pills" style={{ flex: "auto" }}>
              {tabList.map((tab) => (
                <Nav.Item key={tab}>
                  <Nav.Link eventKey={tab}>{tab}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body style={{ height: "91%" }}>
            <Tab.Content className="h-100">
              <Tab.Pane eventKey="General">
                <Card>
                  <Card.Header
                    className="px-2 border-0 bg-gray-200"
                    style={{
                      top: "var(--header-height)",
                      // borderTop: `6px solid`,
                      // borderColor: "var(--module-color)",
                    }}
                  >
                    <div className="d-flex justify-content-between flex-wrap">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className=" d-flex justify-content-between flex-wrap"
                        style={{ marginLeft: 10 }}
                      >
                        <div>
                          <LinkToOrder
                            variant={variant}
                            customerId={variant?.customer?.id}
                            programId={variant?.program?.id}
                            treatmentId={variant?.treatment?.id}
                          />
                        </div>
                        <CopyVariant
                          variant={variant}
                          programId={variant?.program?.id}
                          treatmentId={variant?.treatment?.id}
                          variantId={variantId}
                          mailMomentId={variant?.maiMoment?.id}
                          client={client}
                          parameters={parameters}
                          segmentId={variant?.segment?.id}
                        />
                      </Stack>
                      <div>
                        <DeleteVariantButton
                          variantId={variant?.id}
                          variantName={variant?.name}
                          customerId={variant?.customer?.id}
                        />
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <VariantEditable
                      variant={variant}
                      refetch={refreshVariants}
                    />
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ marginTop: "25px" }}
                    >
                      <div className="w-100">
                        <VariantApproval
                          approval={variant?.approval}
                          customerApproval={variant?.customerApproval}
                          refreshVariants={refreshVariants}
                          refreshHistory={refreshHistory}
                        />
                      </div>
                      <div className="w-100">
                        <VariantCustomerApproval
                          customerApproval={variant?.customerApproval}
                          refreshVariants={refreshVariants}
                          refreshHistory={refreshHistory}
                        />
                      </div>
                      <div
                        style={{
                          width: "30%",
                          position: "relative",
                          right: 25,
                        }}
                      >
                        <SenderApprovalButton
                          customerId={variant?.customer?.id}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="Parameters">
                <VariantParameters
                  parameters={parameters}
                  refreshParameters={refreshParameters}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="Approval history" className="h-100">
                <VariantApprovalHistory records={history?.records} />
              </Tab.Pane>
              <Tab.Pane eventKey="Proof">
                <VariantProofing
                  variant={variant}
                  refreshProofHistory={refreshProofHistory}
                  proofHistory={proofHistory}
                  usedConsumerParameters={usedConsumerParameters}
                />
              </Tab.Pane>
              {tabList
                .filter(
                  (tab) =>
                    ![
                      "General",
                      "Parameters",
                      "Approval history",
                      "Proof",
                    ].includes(tab)
                )
                .map((tab) => (
                  <Tab.Pane eventKey={tab} key={tab}>
                    {tab}
                  </Tab.Pane>
                ))}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </WithLoaderAndError>
    </Layout>
  );
};

export default VariantDetail;
