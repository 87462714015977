import { useEffect, useState } from "react";
import { config } from "../../../config";
import useFetch from "../../../utils/useFetch";

const TreatmentsWrap = ({
  treatments,
  setTreatments,
  component: Component,
}) => {
  const { api } = config;
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;
  const [searchQuery, setSearchQuery] = useState("");

  const dataUrl = `${api.phoenix}/treatments`;

  const params = {
    page: page,
    pageSize: PAGE_SIZE,
    query: searchQuery,
  };

  const { data: allTreatments, headers } = useFetch({
    url: dataUrl,
    params: params,
  });

  const handleClick = (treatment) => {
    if (treatments?.some((t) => t.id === treatment.id)) {
      setTreatments(treatments.filter((t) => t.id !== treatment.id));
    } else {
      setTreatments([...treatments, treatment]);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  return (
    <Component
      allTreatments={allTreatments}
      handleClick={handleClick}
      page={page}
      setPage={setPage}
      PAGE_SIZE={PAGE_SIZE}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      headers={headers}
    />
  );
};
export default TreatmentsWrap;
