import {
  Badge,
  Button,
  FloatingLabel,
  Form,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { config } from "../../../config";
import SearchModal from "../../ui/SearchModal";
import SelectCustomerDrawer from "../../custom/SelectCustomerDrawer";
import { BsX } from "react-icons/bs";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import fetcher from "../../../utils/fetcher";
import useFetch from "../../../utils/useFetch";
import { useAuthContext } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateVariantSearchOrderModal from "./CreateVariantSearchOrderModal";

/**
 * Modal to create a new Customer Variant.
 * We pass in the customer, program, and a callback that closes the modal,
 * plus an optional callback to "go back" to the program selection.
 */
const CreateVariantModal = ({
  show,
  setShowMenu,
  program,
  customer,
  onGoBackToProgramSelect,
  setShowProfileModal,
  showMultiCustomerDrawer,
  setShowMultiCustomer,
  linkToOrder,
}) => {
  const [variantName, setVariantName] = useState("");
  const [description, setDescription] = useState("");

  const [mailMoment, setMailMoment] = useState(null);
  const [segment, setSegment] = useState(null);
  const [treatment, setTreatment] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);

  const [showMailMomentModal, setShowMailMomentModal] = useState(false);
  //const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [showTreatmentModal, setShowTreatmentModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);

  const [additionalSelectedCustomers, setAdditionalSelectedCustomers] =
    useState([]);
  const [customersObject, setCustomersObject] = useState({});

  const { client, setToasts } = useAuthContext();
  const [error, setError] = useState(null);

  const { data: fullProgram } = useFetch({
    url: `${config.api.phoenix}/programs/${program?.id}`,
  });

  const navigate = useNavigate();

  const handleClear = () => {
    setVariantName("");
    setDescription("");
    setMailMoment(null);
    setSegment(null);
    setTreatment(null);
    setAdditionalSelectedCustomers([]);
  };

  const handleCancel = () => {
    handleClear();
    setShowMenu(false);
    if (onGoBackToProgramSelect) {
      onGoBackToProgramSelect();
    }
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to update order and link Variant to Order",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const handleCreateVariant = () => {
    setShowProfileModal(false);
    let allCustomers = [customer];
    if (additionalSelectedCustomers?.length > 0) {
      allCustomers = [customer, ...additionalSelectedCustomers];
    }

    allCustomers = allCustomers.filter(
      (cust, index, self) => self.findIndex((c) => c.id === cust.id) === index
    );

    allCustomers.forEach((cust, index) => {
      const payload = {
        name: variantName || null,
        description: description || null,
        active: true,
        programId: program?.id,
        treatmentId: treatment?.id,
        mailMomentId: mailMoment?.id,
        customerId: cust?.id,
      };

      if (segment && segment !== "") {
        payload.segmentId = segment.id;
      }

      setTimeout(() => {
        fetcher({
          url: `${config.api.phoenix}/variants`,
          method: "POST",
          payload: payload,
          customerId: cust?.id,
          clientId: client?.id,
        })
          .then((result1) => {
            if (cust === allCustomers[0]) {
              navigate(`/variants/${result1?.data?.id}`);
            }
            setToasts((currToasts) => [
              ...currToasts,
              {
                id: Date.now(),
                variant: "success",
                heading: "Success!",
                delay: 30000,
                text: (
                  <div>
                    {"Variant "} <strong>{variantName}</strong>{" "}
                    {"has been successfully created for Customer "}{" "}
                    <strong>{cust?.name}</strong>
                  </div>
                ),
              },
            ]);
            linkToOrder &&
              fetcher({
                url: `${config.api.order}/orders/${orderInfo.id}`,
                method: "GET",
                customerId: customer?.id,
                clientId: client?.id,
              })
                .then((result2) => {
                  const etag = result2.headers.etag;

                  const payload = {
                    orderItemId: orderInfo?.id,
                    productId: orderInfo?.productId,
                    productName: orderInfo?.productName,
                    productCode: orderInfo?.productCode,
                  };

                  fetcher({
                    url: `${config.api.order}/orders/${orderInfo?.id}/variants?variantId=${result1?.data?.id}`,
                    method: "POST",
                    payload: payload,
                    customerId: customer?.id,
                    clientId: client?.id,
                    headers: {
                      "If-Match": etag,
                    },
                  })
                    .then(() => {
                      setToasts((currToasts) => [
                        ...currToasts,
                        {
                          id: Date.now(),
                          variant: "success",
                          heading: "Succes!",
                          delay: 30000,
                          text: (
                            <div>
                              {"Variant "}
                              <strong>{variantName}</strong>
                              {" is successfully linked to Order"}
                            </div>
                          ),
                        },
                      ]);
                    })
                    .catch((err) => {
                      setToasts((currToasts) => [
                        ...currToasts,
                        {
                          id: Date.now(),
                          variant: "danger",
                          heading: (
                            <div>
                              {"An error occurred while linking "}
                              <strong>{variantName}</strong> {" to Order "}{" "}
                            </div>
                          ),
                          delay: 30000,
                          text: createDetailedErrorMessage(err),
                        },
                      ]);
                    });
                })
                .catch((err) => {
                  setError(err);
                });
          })
          .catch((err) => {
            setToasts((currToasts) => [
              ...currToasts,
              {
                id: Date.now(),
                variant: "danger",
                heading: (
                  <div>
                    {"An error occurred while creating "}
                    <strong>{variantName}</strong> {" for Customer "}{" "}
                    <strong>{cust?.name}</strong>
                  </div>
                ),
                delay: 30000,
                text: createDetailedErrorMessage(err),
              },
            ]);
          });
      }, index * 100);
    });
    setShowMenu(false);
  };

  const handleInput = (e) => {
    if (e.code === "Space") {
      e.stopPropagation();
    }
  };

  return (
    <>
      <Modal
        autoFocus
        show={show}
        onHide={handleCancel}
        size="lg"
        centered
        backdrop="static"
        style={
          showMailMomentModal || showTreatmentModal || showMultiCustomerDrawer
            ? { zIndex: 1051 }
            : {}
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {linkToOrder
              ? "Create Variant linked to an Order"
              : "Create Variant manually"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 450, overflowY: "auto" }}>
          <div style={{ padding: 10, paddingLeft: 0 }}>
            <div className="lh-sm d-flex" style={{ fontSize: 18 }}>
              <div style={{ marginRight: 5 }}>Selected Customer:</div>
              <div className="fw-semibold" style={{ marginRight: 5 }}>
                {client?.customer?.name}
              </div>
              <small className="text-muted font-monospace">
                <Badge
                  style={{
                    backgroundColor: "hsla(45, 100.00%, 40.00%, 0.85)",
                  }}
                >
                  {client?.customer?.id}
                </Badge>
              </small>
            </div>
            <div className="lh-sm d-flex" style={{ fontSize: 18 }}>
              <div style={{ marginRight: 5 }}>Selected Program:</div>
              <div className="fw-semibold" style={{ marginRight: 5 }}>
                {program?.name}
              </div>
              <small className="text-muted font-monospace">
                <Badge
                  style={{
                    backgroundColor: "#6b598b",
                  }}
                >
                  {program?.id}
                </Badge>
              </small>
            </div>
          </div>
          <Form.Group className="mb-3">
            <FloatingLabel label="Variant Name*">
              <Form.Control
                style={{ width: "50%" }}
                type="text"
                value={variantName}
                onChange={(e) => setVariantName(e.target.value)}
                onKeyDown={handleInput}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3">
            <FloatingLabel label="Description" placeholder="None selected">
              <Form.Control
                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onKeyDown={handleInput}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3">
            <div className="d-flex align-items-center w-100">
              <FloatingLabel label="Mail Moment*" className="w-100">
                <Form.Control
                  disabled
                  type="text"
                  value={mailMoment?.name || ""}
                  onChange={(e) => setMailMoment(e.target.value)}
                />
              </FloatingLabel>

              <Button
                variant={!!mailMoment ? "secondary" : "outline-secondary"}
                onClick={() => setShowMailMomentModal(true)}
                style={{ marginLeft: 10 }}
              >
                Select
              </Button>
              {mailMoment && (
                <Button
                  variant="danger"
                  onClick={() => setMailMoment("")}
                  style={{ marginLeft: 5 }}
                >
                  <BsX />
                </Button>
              )}
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <div className="d-flex align-items-center w-100">
              <FloatingLabel label="Treatment*" className="w-100">
                <Form.Control
                  type="text"
                  disabled
                  value={treatment?.name || ""}
                  onChange={(e) => setTreatment(e.target.value)}
                />
              </FloatingLabel>

              <Button
                variant={!!treatment ? "secondary" : "outline-secondary"}
                onClick={() => setShowTreatmentModal(true)}
                style={{ marginLeft: 10 }}
              >
                Select
              </Button>
              {treatment && (
                <Button
                  variant="danger"
                  onClick={() => setTreatment(null)}
                  style={{ marginLeft: 5 }}
                >
                  <BsX />
                </Button>
              )}
            </div>
          </Form.Group>

          {linkToOrder && (
            <Form.Group className="mb-3">
              <div className="d-flex align-items-center w-100">
                <FloatingLabel label="Order*" className="w-100">
                  <Form.Control
                    type="text"
                    disabled
                    value={orderInfo?.productName || ""}
                    onChange={(e) => setOrderInfo(e.target.value)}
                  />
                </FloatingLabel>

                <Button
                  disabled={!treatment || !mailMoment}
                  variant={!!orderInfo ? "secondary" : "outline-secondary"}
                  onClick={() => setShowOrderModal(true)}
                  style={{ marginLeft: 10 }}
                >
                  Select
                </Button>
                {orderInfo && (
                  <Button
                    variant="danger"
                    onClick={() => setOrderInfo(null)}
                    style={{ marginLeft: 5 }}
                  >
                    <BsX />
                  </Button>
                )}
              </div>
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <div className="d-flex align-items-center w-100">
              <FloatingLabel label="Segment" className="w-100">
                <Form.Control
                  type="text"
                  disabled
                  value={segment?.name || ""}
                  onChange={(e) => setSegment(e.target.value)}
                />
              </FloatingLabel>

              <Button
                variant="outline-secondary"
                // onClick={() => setShowSegmentModal(true)}
                style={{ marginLeft: 10 }}
                disabled
              >
                Select
              </Button>
              {segment && (
                <Button
                  variant="danger"
                  onClick={() => setSegment("")}
                  style={{ marginLeft: 5 }}
                >
                  <BsX />
                </Button>
              )}
            </div>
          </Form.Group>

          {linkToOrder === false && (
            <div className="d-flex justify-content-between w-100 align-items-center">
              <div>
                <ListGroup className="w-100">
                  {!!customersObject?.customers &&
                  customersObject?.customers?.length > 0
                    ? customersObject.customers
                        .reduce((rows, cus, index) => {
                          const chunkIndex = Math.floor(index / 3); // Change 5 to your desired max items per row
                          if (!rows[chunkIndex]) rows[chunkIndex] = [];
                          rows[chunkIndex].push(cus);
                          return rows;
                        }, [])
                        .map((row, rowIndex) => (
                          <div key={`row-${rowIndex}`} className="d-flex">
                            {row.map((cus, index) => (
                              <ListGroup.Item
                                key={`${cus.id}-${index}`}
                                variant="secondary"
                                className="text-truncate"
                                style={{
                                  width: "170px",
                                  height: "50px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor:
                                    "hsla(45, 100.00%, 40.00%, 0.65)",

                                  borderRadius: "6px",
                                  boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                  transition: "transform 0.2s, box-shadow 0.2s",
                                }}
                              >
                                {cus.name.length > 20
                                  ? `${cus.name.slice(0, 20)}...`
                                  : cus.name}
                              </ListGroup.Item>
                            ))}
                          </div>
                        ))
                    : null}
                </ListGroup>
              </div>

              <div>
                <Button
                  onClick={() => setShowMultiCustomer(true)}
                  variant={
                    !!customersObject?.customers &&
                    customersObject?.customers?.length > 0
                      ? "secondary"
                      : "outline-secondary"
                  }
                >
                  Add extra Customers
                </Button>
                {!!customersObject?.customers &&
                  customersObject?.customers?.length > 0 && (
                    <Button
                      variant="danger"
                      onClick={() => {
                        setCustomersObject({});
                        setAdditionalSelectedCustomers([]);
                      }}
                      style={{ marginLeft: 5 }}
                    >
                      <BsX />
                    </Button>
                  )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleCreateVariant}
            disabled={
              !variantName ||
              !treatment ||
              !mailMoment ||
              (linkToOrder && !orderInfo)
            }
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MailMoment */}
      {!!fullProgram && fullProgram?.mailMoments ? (
        <SearchModal
          programName={program?.name}
          search={false}
          items={fullProgram?.mailMoments}
          show={showMailMomentModal}
          onHide={() => setShowMailMomentModal(false)}
          title={"Select Mail Moment"}
          onSelect={(item) => setMailMoment(item)}
        />
      ) : null}

      {/* Treatment */}
      {!!program ? (
        <SearchModal
          type="treatment"
          show={showTreatmentModal}
          onHide={() => setShowTreatmentModal(false)}
          title={"Select Treatment"}
          fetchUrl={`${config.api.phoenix}/treatments`}
          directionOptions={["ascending", "descending"]}
          orderOptions={["name", "code", "id"]}
          onSelect={(item) => setTreatment(item)}
        />
      ) : null}
      {linkToOrder && !!program && !!treatment && !!customer ? (
        <CreateVariantSearchOrderModal
          show={showOrderModal}
          setShow={setShowOrderModal}
          setOrderInfo={setOrderInfo}
          treatmentId={treatment?.id}
          programId={program?.id}
          customerId={customer?.id}
        />
      ) : null}
      {/* Segment */}
      {/* {!!program ? (
          <SearchModal
            type="segment"
            show={showSegmentModal}
            onHide={() => setShowSegmentModal(false)}
            title={"Select Segment"}
  
            search={false}
            onSelect={(item) => setSegment(item)}
          />
        ) : null} */}
      <SelectCustomerDrawer
        show={showMultiCustomerDrawer}
        setShow={setShowMultiCustomer}
        closeButton
        multiSelect
        onSelect={setCustomersObject}
        selectedCustomers={additionalSelectedCustomers}
        setSelectedCustomers={setAdditionalSelectedCustomers}
      />
    </>
  );
};

export default CreateVariantModal;
