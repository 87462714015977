import { ListGroup } from "react-bootstrap";
import MailMomentsWrap from "../../Wraps/MailMomentsWrap";
import { BsCheck2 } from "react-icons/bs";

const MailMomentListGroup = ({ mailMoment, setMailMoment, programId }) => {
  return (
    <MailMomentsWrap
      mailMoment={mailMoment}
      setMailMoment={setMailMoment}
      programId={programId}
      component={({ mailMoments, handleClick }) => (
        <ListGroup
          className="scrollbar-thin"
          style={{
            "--bs-list-group-active-bg": "#4b626b",
            "--bs-list-group-active-border-color": "#4b626b",
            maxHeight: 350,
            marginTop: 10,
            overflowY: "auto",
            paddingTop: 10,
            cursor: "pointer",
          }}
        >
          {!!mailMoments && mailMoments.length > 0
            ? mailMoments.map((item) => (
                <ListGroup.Item
                  active={mailMoment?.id === item.id}
                  key={item.id}
                  action
                  onClick={() => handleClick(item)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{item.name}</span>
                    {mailMoment?.id === item.id && (
                      <BsCheck2 className="text-success" />
                    )}
                  </div>
                </ListGroup.Item>
              ))
            : null}
        </ListGroup>
      )}
    />
  );
};

export default MailMomentListGroup;
