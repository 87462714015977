import { Badge, Form, ListGroup } from "react-bootstrap";
import SearchBox from "../../../ui/SearchBox";
import CustomersWrap from "../../Wraps/CustomersWrap";
import PaginationFromHeader from "../../../ui/PaginationFromHeader";
import ImgBox from "../../../ui/ImgBox";
import { FaStore } from "react-icons/fa6";

const CustomersListGroup = ({ customers, setCustomers }) => {
  return (
    <CustomersWrap
      customers={customers}
      setCustomers={setCustomers}
      component={({
        allCustomers,
        handleClick,
        page,
        setPage,
        PAGE_SIZE,
        searchQuery,
        setSearchQuery,
        headers,
      }) => (
        <>
          <SearchBox
            className="w-100"
            placeholder="Search Customers"
            query={searchQuery}
            setQuery={setSearchQuery}
            maxWidth={600}
            size="md"
            clearSearchStyle={{
              position: "absolute",
              zIndex: "999",
              top: "7px",
              left: "575px",
              color: "red",
            }}
          />

          <ListGroup
            className="scrollbar-thin"
            style={{
              "--bs-list-group-active-bg": "#4b626b",
              "--bs-list-group-active-border-color": "#4b626b",
              maxHeight: 350,
              marginTop: 10,
              overflowY: "auto",
              paddingTop: 10,
              cursor: "pointer",
            }}
          >
            {!!allCustomers && allCustomers.length > 0 ? (
              allCustomers.map((item) => {
                const isSelected = customers?.some((c) => c.id === item.id);

                return (
                  <ListGroup.Item
                    key={item.id}
                    active={isSelected}
                    onClick={() => handleClick(item)}
                  >
                    <div
                      className="d-flex justify-content-between w-100"
                      style={{ paddingLeft: 3, paddingRight: 3 }}
                    >
                      <div className="w-100 d-flex align-items-center">
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                          className="border-end me-2 overflow-hidden d-flex align-items-center"
                        >
                          <ImgBox
                            className="d-flex align-items-center"
                            imgId={item?.logoFileId}
                            width={48}
                            icon={FaStore}
                            imgBg="secondary"
                            resizeMode="contain"
                            style={{ with: 48, height: "100%" }}
                          />
                        </div>
                        <div className="lh-sm" style={{ fontSize: "15px" }}>
                          <div className="fw-semibold">{item.name}</div>
                          <small className="text-muted font-monospace">
                            <Badge
                              style={{
                                backgroundColor:
                                  "hsla(45, 100.00%, 40.00%, 0.85)",
                                color: "white",
                              }}
                              className={`${
                                isSelected ? "" : "hover-opacity-0"
                              }text-light text-opacity-75 font-monospace me-2 ms-n1`}
                            >
                              {item.id}
                            </Badge>
                          </small>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end align-items-center">
                        <Form.Check
                          type="checkbox"
                          checked={isSelected}
                          readOnly
                          className="me-2"
                        />
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })
            ) : (
              <span
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: 100 }}
              >
                No Customer found
              </span>
            )}
          </ListGroup>
          {!!headers?.link ? (
            <div
              className="d-flex justify-content-center"
              style={{ paddingTop: 10 }}
            >
              <PaginationFromHeader
                current={page}
                linkStr={headers.link}
                setPage={setPage}
                showButtons
                pageSize={PAGE_SIZE}
              />
            </div>
          ) : null}
        </>
      )}
    />
  );
};

export default CustomersListGroup;
