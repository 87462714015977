import React, { useState } from "react";

const VariantFilterWrap = ({
  program,
  setProgram,
  customers,
  setCustomers,
  treatments,
  setTreatments,
  mailMoment,
  setMailMoment,
  component: Component,
}) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClear = () => {
    if (!!program) {
      setProgram(null);
      setMailMoment(null);
    }
    if (!!customers && !!customers?.length > 0) {
      setCustomers([]);
    }
    if (!!treatments && !!treatments?.length > 0) {
      setTreatments([]);
    }
    if (!!mailMoment) {
      setMailMoment(null);
    }
  };

  return (
    <Component
      show={show}
      handleOpen={handleOpen}
      handleClose={handleClose}
      mailMoment={mailMoment}
      program={program}
      customers={customers}
      treatments={treatments}
      handleClear={handleClear}
      setCustomers={setCustomers}
      setTreatments={setTreatments}
      setProgram={setProgram}
      setMailMoment={setMailMoment}
    />
  );
};

export default VariantFilterWrap;
