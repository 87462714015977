import { Badge, Button, Collapse, Form, ListGroup } from "react-bootstrap";
import FulfilmentPartnersWrap from "./FulfilmentPartnersWrap";
import { BsCaretDown, BsCaretUp, BsSliders, BsX } from "react-icons/bs";
import PaginationFromHeader from "../../ui/PaginationFromHeader";

const FulfilmentPartnerFilter = ({
  fulfilmentPartnerFilter,
  setFulfilmentPartnerFilter,
  fulfilmentPartner,
  setFulfilmentPartner,
  fulfilmentPartners,
  setFulfilmentPartners,
}) => {
  return (
    <FulfilmentPartnersWrap
      fulfilmentPartner={fulfilmentPartner}
      setFulfilmentPartner={setFulfilmentPartner}
      fulfilmentPartners={fulfilmentPartners}
      setFulfilmentPartners={setFulfilmentPartners}
      fulfilmentPartnerFilter={fulfilmentPartnerFilter}
      setFulfilmentPartnerFilter={setFulfilmentPartnerFilter}
      component={({
        fps,
        headers,
        page,
        pageSize,
        setPage,
        advancedOptions,
        isExpanded,
        toggleExpandedView,
        toggleAdvancedOptions,
        handleClick,
        handleWithCheck,
        handleWithoutCheck,
        clearFulfillmentPartners,
        count,
        hoverBadge,
        setHoverBadge,
      }) => (
        <div>
          <div className="d-flex align-items-center justify-content-between mb-2 w-100">
            <h5 className="mb-0">Fulfilment Partners</h5>
            <div className="position-relative">
              <Button
                variant="link"
                onClick={toggleExpandedView}
                className="position-relative"
              >
                {isExpanded ? <BsCaretUp /> : <BsCaretDown />}
              </Button>

              {count > 0 && (
                <Badge
                  pill
                  bg={hoverBadge ? "danger-tint-10" : "flush"}
                  className="position-absolute top-0 transition-bg"
                  title="Clear"
                  style={{
                    left: 30,
                    paddingLeft: ".4em",
                    paddingRight: ".4em",
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => setHoverBadge(true)}
                  onMouseLeave={() => setHoverBadge(false)}
                  onClick={clearFulfillmentPartners || (() => {})}
                >
                  <BsX />
                </Badge>
              )}
            </div>
          </div>

          <Collapse in={isExpanded}>
            <div>
              <div
                className="d-flex justify-content-between align-items-center mb-3"
                style={{ height: 50 }}
              >
                <Button
                  variant={advancedOptions ? "secondary" : "outline-secondary"}
                  size="sm"
                  onClick={toggleAdvancedOptions}
                  className="position-relative z-1"
                >
                  <BsSliders color={advancedOptions ? "white" : ""} />
                </Button>

                <Collapse
                  in={advancedOptions}
                  dimension="width"
                  className={`ms-2 flex-grow-1 collapse-transition`}
                >
                  <div id="example-fade-text">
                    <div className="d-flex align-items-center justify-content-center">
                      <small className="text-muted fw-bold">Filter type:</small>
                    </div>
                    <div className="d-flex align-items-center ms-3">
                      <Form.Check
                        type="checkbox"
                        label="With"
                        className="me-3"
                        onChange={handleWithCheck}
                        checked={fulfilmentPartnerFilter === "with"}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Without"
                        onChange={handleWithoutCheck}
                        checked={fulfilmentPartnerFilter === "without"}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>

              <Collapse
                in={
                  fulfilmentPartnerFilter === "" ||
                  fulfilmentPartnerFilter === "with"
                }
              >
                <ListGroup
                  style={{
                    maxHeight: 350,
                    overflowY: "auto",
                    "--bs-list-group-active-bg": "#4b626b",
                    "--bs-list-group-active-border-color": "#4b626b",
                    cursor: "pointer",
                  }}
                  className="scrollbar-thin"
                >
                  {fps?.map((fp) => {
                    const isSelected = advancedOptions
                      ? fulfilmentPartners?.some((f) => f.id === fp.id)
                      : fulfilmentPartner?.id === fp.id;

                    return (
                      <ListGroup.Item
                        key={fp.id}
                        action
                        active={isSelected}
                        onClick={() => handleClick(fp)}
                        className="d-flex align-items-center py-2"
                      >
                        {advancedOptions && (
                          <Form.Check
                            type="checkbox"
                            checked={isSelected}
                            readOnly
                            className="me-3 flex-shrink-0"
                          />
                        )}
                        <span style={{ fontSize: "13px" }}> {fp.name}</span>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Collapse>

              {headers?.link && (
                <div className="mt-3">
                  <PaginationFromHeader
                    current={page}
                    linkStr={headers.link}
                    setPage={setPage}
                    showButtons
                    pageSize={pageSize}
                  />
                </div>
              )}
            </div>
          </Collapse>
        </div>
      )}
    />
  );
};

export default FulfilmentPartnerFilter;
