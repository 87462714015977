import React, { useEffect, useState } from "react";
import { config } from "../../config";
import ImgBox from "../ui/ImgBox";
import ListPage from "../ui/ListPage";
import { Dropdown, ButtonGroup, Card } from "react-bootstrap";
import { BsBriefcaseFill, BsCheck2, BsFunnelFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaStore } from "react-icons/fa6";
import CustomerView from "./CustomerDetail/CustomerView";
import CustomerListItem, {
  CustomerListHeader,
} from "./CustomerDetail/CustomerListItem";
import AddNewCustomer from "./CustomerDetail/AddNewCustomer";
import useStorage from "../../context/StorageContext";
import useListPage from "../../utils/useListPage";
import FulfilmentPartnerFilter from "./CustomerDetail/FulfilmentPartnerFilter";

const Customers = () => {
  const [state, setValue] = useListPage("customers");

  const {
    activeFilter = "",
    fulfilmentPartnerFilter = "",
    fulfilmentPartner = null,
    fulfilmentPartners = [],
  } = state;

  const [fulfilmentPartnerIds, setFulfilmentPartnerIds] = useState([]);

  useEffect(() => {
    setFulfilmentPartnerIds((prev) => {
      const newIds =
        fulfilmentPartners.length > 0
          ? fulfilmentPartners.map((fp) => fp.id)
          : fulfilmentPartner
          ? [fulfilmentPartner.id]
          : [];

      return JSON.stringify(prev) === JSON.stringify(newIds) ? prev : newIds;
    });
  }, [fulfilmentPartners, fulfilmentPartner]);

  const setActiveFilter = (val) => setValue({ activeFilter: val, page: 1 });
  const setFulfilmentPartnerFilter = (val) =>
    setValue({ fulfilmentPartnerFilter: val, page: 1 });
  const setFulfilmentPartner = (val) =>
    setValue({ fulfilmentPartner: val, page: 1 });
  const setFulfilmentPartners = (val) =>
    setValue({ fulfilmentPartners: val, page: 1 });

  const ACTIVE_FILTER_OPTIONS = ["active", "inactive"];

  const onClear = () => {
    setFulfilmentPartnerFilter("");
    setFulfilmentPartner(null);
    setFulfilmentPartners([]);
    setActiveFilter("");
  };

  const filterCount =
    (activeFilter ? 1 : 0) +
    (fulfilmentPartnerFilter ? 1 : 0) +
    (fulfilmentPartner ? 1 : 0) +
    (fulfilmentPartners.length > 0 ? fulfilmentPartners.length : 0);

  const ActiveFilterSelect = () => {
    return (
      <Dropdown as={ButtonGroup} className="filter-menu">
        <Dropdown.Toggle
          variant="outline-secondary"
          size="sm"
          active={!!activeFilter}
          title="filter"
        >
          <BsFunnelFill />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {ACTIVE_FILTER_OPTIONS.map((option) => (
            <Dropdown.Item
              as="span"
              className="cursor-pointer"
              onClick={() =>
                setActiveFilter(activeFilter === option ? "" : option)
              }
              active={activeFilter === option}
              key={option}
            >
              <BsCheck2
                className={`${
                  activeFilter !== option ? "opacity-0" : ""
                } text-success me-2`}
              />
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const { setCustomer } = useStorage();

  const GridItem = ({ item }) => {
    return (
      <Link
        className="text-decoration-none hover-parent d-flex align-items-center h-100 d-block"
        to={`/customers/${item.id}`}
        onClick={() =>
          setCustomer({
            id: item?.id,
            name: item?.name,
            logoFileId: item?.logoFileId,
          })
        }
      >
        <Card className="group p-2 bg-gray-200 d-flex justify-content-between h-100 thumbnail-card customer-grid-card fade-in-grow-up delay w-100">
          <Card.Body className="p-2 w-100 bg-white h-100">
            <div className="p-0" style={{ marginLeft: 5 }}>
              {!!item?.fulFilmentPartnerId && (
                <span className="text-secondary opacity-75 fs-75">
                  <BsBriefcaseFill className="me-1 opacity-75 fs-90" />
                  {item.fulFilmentPartnerName}
                </span>
              )}
            </div>
            <ImgBox
              imgId={item?.logoFileId}
              width={200}
              padding={10}
              className="fade-in-grow delay d-flex align-items-center"
              icon={FaStore}
              imgBg="white"
              imgColor="rgba(var(--bs-px-dark-rgb), .2)"
              resizeMode="contain"
              style={{
                "--img-box-aspect-ratio": "50%",
              }}
            />
            <div className="bg-white">
              <Card.Title
                as="h6"
                className="mb-1 p-0"
                style={{ fontSize: 14, marginBottom: 0 }}
              >
                {item.name}
              </Card.Title>
              <span className="text-secondary opacity-75 fs-75">
                {item.city}
              </span>
            </div>
          </Card.Body>
        </Card>
      </Link>
    );
  };

  return (
    <ListPage
      baseUrl={`${config.api.phoenix}/customers`}
      urlParams={`&filter=${activeFilter}&fulfilmentPartnerFilter=${fulfilmentPartnerFilter}${
        fulfilmentPartnerIds.length > 0
          ? fulfilmentPartnerIds
              .map((id) => `&fulfilmentPartnerIds=${id}`)
              .join("&")
          : ""
      }`}
      itemLabel="customer"
      itemPath="customers/view"
      orderOptions={["id", "code", "name"]}
      searchProps={{
        title: "Name, description, code, ID",
      }}
      newItemBtn={<AddNewCustomer />}
      gridItem={GridItem}
      viewItem={CustomerView}
      listItem={CustomerListItem}
      listHeader={CustomerListHeader}
      thumbnailCol="logoFileId"
      tableItems={{
        logoFileId: {
          // displayHeading: " ",
          cellType: "thumbnail",
          cellPadding: "0px",
          cellWidth: 56,
          component: ({ value }) => (
            <ImgBox
              imgId={value}
              width={54}
              height={32}
              ratio={false}
              padding={2}
              className="img-box-aspect-ratio-65"
              icon={FaStore}
              imgBg="rgba(255,255,255,.7)"
              imgColor="rgba(var(--bs-px-dark-rgb), .2)"
              resizeMode="contain"
            />
          ),
        },
        id: {},
        code: {},
        name: { cellWidth: 150 },
        city: { cellWidth: 100 },
        fulFilmentPartnerName: {
          displayHeading: "Fulfilment partner",
        },
      }}
      headerItems={<ActiveFilterSelect />}
      layoutOptions={["list", "table", "grid"]}
      defaultLayout="grid"
      menuType="customers"
      colorClass="customer"
      storageKey="customers"
      onClear={onClear}
      filterCount={filterCount}
      filters={
        <div className="w-100">
          <div>
            Active | Inactive | All <hr />
          </div>
          <FulfilmentPartnerFilter
            fulfilmentPartnerFilter={fulfilmentPartnerFilter}
            setFulfilmentPartnerFilter={setFulfilmentPartnerFilter}
            fulfilmentPartner={fulfilmentPartner}
            setFulfilmentPartner={setFulfilmentPartner}
            fulfilmentPartners={fulfilmentPartners}
            setFulfilmentPartners={setFulfilmentPartners}
          />
        </div>
      }
    />
  );
};

export default Customers;
