import { useEffect, useState } from "react";
import useFetch from "../../../../utils/useFetch";
import { config } from "../../../../config";
import { useAuthContext } from "../../../../context/AuthContext";
import fetcher from "../../../../utils/fetcher";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";

const SenderApprovalWrap = ({ customerId, component: Component }) => {
  const { client, setToasts } = useAuthContext();
  const [showModal, setShowModal] = useState(false);
  const [treatmentPage, setTreatmentPage] = useState(1);
  const [contactPersonsPage, setContactPersonsPage] = useState(1);
  const [filteredContactPersons, setFilteredContactPersons] = useState(null);
  const [selectedContactPersons, setSelectedContactPersons] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [error, setError] = useState(null);

  const PAGE_SIZE = 30;

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedContactPersons([]);
    setSelectedTreatment(null);
  };

  const handleSelectTreatment = (treatment) => {
    if (treatment.id === selectedTreatment?.id) {
      setSelectedTreatment(null);
    } else {
      setSelectedTreatment(treatment);
    }
  };

  const handleSelectContactPersons = (contactPerson) => {
    setSelectedContactPersons((prev) =>
      prev?.some((c) => c.id === contactPerson.id)
        ? prev.filter((c) => c.id !== contactPerson.id)
        : [...prev, contactPerson]
    );
  };

  const handleSendApproval = () => {
    const recipientEmails = selectedContactPersons
      .map(
        (person) =>
          person.contactDetails.find((detail) => detail.type === "Email")?.value
      )
      .filter((email) => email);

    fetcher({
      url: `${config.api.phoenix}/customers/${customerId}/sendapprovalreminder`,
      payload: {
        treatmentId: selectedTreatment?.id,
        recipients: recipientEmails,
      },
      method: "POST",
      customerId: customerId,
      clientId: client?.id,
    })
      .then(() => {
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            heading: "Succes!",
            delay: 30000,
            text: "Reminder successfully send",
          },
        ]);
        handleCloseModal();
      })
      .catch((err) => {
        setError(err);
        handleCloseModal();
      });
  };

  const paramsTreatments = {
    page: treatmentPage,
    pageSize: PAGE_SIZE,
    treatmentType: "customerApproval",
  };

  const paramsContactPersons = {
    page: contactPersonsPage,
    pageSize: PAGE_SIZE,
  };

  const { data: treatments, headers: treatmentsHeaders } = useFetch({
    url: `${config.api.phoenix}/treatments`,
    params: paramsTreatments,
    customerId,
    clientId: client?.id,
    shouldFetch: () => showModal === true,
  });

  const { data: contactPersons, headers: contactPersonsHeaders } = useFetch({
    url: `${config.api.phoenix}/customers/${customerId}/persons`,
    params: paramsContactPersons,

    shouldFetch: () => showModal === true,
  });

  useEffect(() => {
    if (contactPersons) {
      const filtered = contactPersons.filter(
        (person) => person.contactDetails && person.contactDetails.length > 0
      );
      setFilteredContactPersons(filtered);
    }
  }, [contactPersons]);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to send customer approval",
          delay: 30000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Component
      showModal={showModal}
      handleShowModal={handleShowModal}
      handleCloseModal={handleCloseModal}
      treatments={treatments}
      treatmentsLink={treatmentsHeaders?.link}
      contactPersons={filteredContactPersons}
      contactPersonsLink={contactPersonsHeaders?.link}
      handleSelectTreatment={handleSelectTreatment}
      handleSendApproval={handleSendApproval}
      handleSelectContactPersons={handleSelectContactPersons}
      selectedTreatment={selectedTreatment}
      selectedContactPersons={selectedContactPersons}
      pageSize={PAGE_SIZE}
      contactPersonsPage={contactPersonsPage}
      treatmentPage={treatmentPage}
      setTreatmentPage={setTreatmentPage}
      setContactPersonsPage={setContactPersonsPage}
    />
  );
};
export default SenderApprovalWrap;
